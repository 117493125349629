import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import en from 'vuetify/es5/locale/en'
import vi from './vi'

Vue.use(Vuetify);

const currentLocale = localStorage.getItem('locale') || 'en'
const currentDark = Boolean(localStorage.getItem('dark')) || false

const options = {
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: { customProperties: true },
    dark: currentDark,
    themes: {
      light: {
        bar: '#0fa632',
        primary: '#50b754',
        accent: '#6ecc6d',
        tab: '#76d275',
        secondary: '#f2f8fc',
        backgroundInput: '#F1F9FF',
        backgroundForm: '#f6fff6',
        backgroundList: '#d9fcd9',
        cancel: '#e8f5e9',
        info: '#058c0b',
        warning: '#ffc107',
        error: '#c41c00',
        success: colors.green.darken2
      },
      dark: {
        bar: '#087f23',
        primary: '#43a047',
        accent: '#6abf69',
        tab: '#76d275',
        secondary: '#f2f8fc',
        backgroundInput: '#F1F9FF',
        backgroundForm: '#76D2759E',
        cancel: '#e8f5e9',
        info: '#00B686',
        warning: '#ffc107',
        error: '#c41c00',
        success: colors.green.darken2
      }
    }
  },
  lang: {
    locales: {en, vi},
    current: currentLocale
  }
};

export const vuetify = new Vuetify(options);

export function toggleDark() {
  vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
  localStorage.setItem('dark', vuetify.framework.theme.dark ? 'true': '')
}
