import {getRecordDetail} from "@/backend";
import utils from "@/ultis/genUUID";

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {
  },
  actions: {
    getDetailRecord({state}, record) {
      try {
        return getRecordDetail({id: record.id, surveyId: record.survey_id})
      } catch (e) {
      } finally {
      }
    }
  }
}
