import api from '@/api'
import config from '@/config.json'

export async function authLogin(data) {
  let result = await api.post(`${config.backend}/auth/login`, data)
  api.setToken("Bearer " + result.data.access_token)
  return result
}

export async function checkToken() {
  return await api.get(`${config.backend}/auth/me`)
}

export async function authLogout() {
  await api.post(`${config.backend}/auth/logout`)
  api.deleteToken()
  window.location.href = '/'
}

export async function resetPassword(data) {
  return await api.post(`${config.backend}/auth/password/reset`, data)
}

export async function register(data) {
  return await api.post(`${config.backend}/auth/register`, data)
}

export async function getCountry() {
  return await api.get(`${config.backend}/territories/countries`)
}

export async function getState() {
  return await api.get(`${config.backend}/territories/states`)
}

export async function getDistrict(data) {
  return await api.get(`${config.backend}/territories/districts`, data)
}

export async function getSubDistrict(data) {
  return await api.get(`${config.backend}/territories/sub-districts`, data)
}

export async function getRole() {
  return await api.get(`${config.backend}/config/roles`)
}

export async function updateProfile(data) {
  return await api.post(`${config.backend}/profile`, data)
}

//user//

export async function getAllUser(data) {
  return await api.get(`${config.backend}/admin/users`, data)
}

export async function assignee(data) {
  return await api.post(`${config.backend}/admin/surveys/${data.surveyId}/assign`, data.data)
}

export async function createUser(data) {
  return await api.post(`${config.backend}/admin/users`, data)
}

export async function updateUser(form) {
  return await api.put(`${config.backend}/admin/users/${form.id}`, form.data)
}

export async function deleteUser(id) {
  return await api.delete(`${config.backend}/admin/users/${id}`)
}

export async function getDataDashBoard(data) {
  return await api.get(`${config.backend}/imageries/statistics`, data)
}

export async function forgotPassword(data) {
  return await api.post(`${config.backend}/auth/password/email`, data)
}

//Survey//

export async function getAllSurvey(data) {
  return await api.get(`${config.backend}/admin/surveys`, data)
}

export async function createSurvey(data) {
  return await api.post(`${config.backend}/admin/surveys`, data)
}

export async function updateSurvey(form) {
  return await api.put(`${config.backend}/admin/surveys/${form.id}`, form.data)
}

export async function deleteSurvey(id) {
  return await api.delete(`${config.backend}/admin/surveys/${id}`)
}

export async function getFieldConfig() {
  return await api.get(`${config.backend}/config/rules`)
}

export async function getFieldDefaultConfig() {
  return await api.get(`${config.backend}/config/fixed-fields`)
}

//Option//

export async function getOption(data) {
  return await api.get(`${config.backend}/admin/options`, data)
}

export async function getOptionDetail(form) {
  return await api.get(`${config.backend}/admin/options/${form.id}/choices`, form.data)
}

export async function createOption(data) {
  return await api.post(`${config.backend}/admin/options`, data)
}

export async function updateOption(form) {
  return await api.put(`${config.backend}/admin/options/${form.id}`, form.data)
}

export async function deleteOption(id) {
  return await api.delete(`${config.backend}/admin/options/${id}`)
}

export async function deleteChoice(data) {
  return await api.delete(`${config.backend}/admin/options/${data.id}/choices/${data.choiceId}`)
}

export async function saveItemOption(form) {
  return await api.put(`${config.backend}/admin/options/${form.id}/choices/${form.choiceId}`, form.data)
}

export async function addItemOption(form) {
  return await api.post(`${config.backend}/admin/options/${form.id}/choices`, form.data)
}

//Option//

export async function getDashboard(data) {
  return await api.get(`${config.backend}/dashboard`, data)
}

export async function getRecords(data) {
  return await api.get(`${config.backend}/dashboard/records`, data)
}

export async function getRecordDetail(data) {
  return await api.get(`${config.backend}/dashboard/surveys/${data.surveyId}/records/${data.id}`)
}

export async function updateStatusRecord(data) {
  return await api.post(`${config.backend}/dashboard/surveys/${data.surveyId}/records/${data.id}/${data.action}`, {
    updated_geometry: data.updated_geometry
  })
}

export async function downloadData(form) {
  return await api.get(`${config.backend}/dashboard/surveys/${form.surveyId}/download`, form.data)
}

export async function getRef(id) {
  return await api.get(`${config.backend}/admin/options/${id}/children`)
}

export async function updateStatusSurvey(form) {
  return await api.post(`${config.backend}/admin/surveys/${form.id}/publish`, form.data)
}

export async function getRefChoice(form) {
  return await api.get(`${config.backend}/admin/options/${form.id}/choices/${form.choiceId}/children`)
}

export async function uploadOption(form) {
  return await api.post(`${config.backend}/admin/options/import`, form)
}

export async function downloadTemplate(form) {
  return await api.get(`${config.backend}/dashboard/surveys/${form.surveyId}/download`, form.data)
}


export async function getListChartFields() {
  return await api.get(`${config.backend}/dashboard/fields`)
}

export async function uploadImage(form) {
  return await api.post(`${config.backend}/dashboard/surveys/${form.surveyId}/records/${form.id}/upload-image`, form.data)
}

export async function removeImage(form) {
  return await api.delete(`${config.backend}/dashboard/surveys/${form.surveyId}/records/${form.id}/image`, form.data)
}

//sysadmin

export async function getExampleSurvey(data) {
  return await api.get(`${config.backend}/sysadmin/example-surveys`, data)
}

export async function createExampleSurvey(data) {
  return await api.post(`${config.backend}/sysadmin/example-surveys`, data)
}

export async function updateExampleSurvey(form) {
  return await api.put(`${config.backend}/sysadmin/example-surveys/${form.id}`, form.data)
}

export async function deleteExampleSurvey(id) {
  return await api.delete(`${config.backend}/sysadmin/example-surveys/${id}`)
}

export async function getListCompany(param) {
  return await api.get(`${config.backend}/sysadmin/companies`, param)
}

export async function updateCompany(form) {
  return await api.put(`${config.backend}/sysadmin/companies/${form.id}`, form.data)
}

export async function updateToken(form) {
  return await api.post(`${config.backend}/sysadmin/tokens/add`, form.data)
}

export async function addExampleSurvey() {
  return await api.post(`${config.backend}/admin/example-survey`)
}

//dashboard

export async function updateField(form) {
  return await api.put(`${config.backend}/dashboard/surveys/${form.surveyId}/records/${form.id}`, form.data)
}

export async function getAllDashboardSurvey(data) {
  return await api.get(`${config.backend}/dashboard/surveys`, data)
}

export async function changePassword(form) {
  return await api.put(`${config.backend}/auth/change-password`, form.data)
}

export async function resendEmail() {
  return await api.get(`${config.backend}/auth/resend-email`)
}

export async function connectMonitoring(data) {
  return await api.post(`${config.backend}/auth/link-account`, data)
}

//Payment

export async function getPaymentLink(form) {
  return await api.post(`${config.backend}/admin/payment/prices/1/checkout`, form.data)
}

export async function linkMonitoring(data) {
  return await api.post(`${config.backend}/auth/link-survey-account`, data)
}

export async function getPaymentHistory(param) {
  return await api.get(`${config.backend}/admin/logs/token-log`, param)
}




