<template>
  <v-dialog
      persistent
      v-model="connectForm"
      width="450">
    <v-card style="border-radius: 8px">
      <!--      <v-card-title class="popup-header">-->
      <!--        <span style="font-size: 16px; text-transform: uppercase">Connect to Survey</span>-->
      <!--        <v-spacer/>-->
      <!--        <v-btn icon small @click="connectForm = false">-->
      <!--          <v-icon>mdi-close</v-icon>-->
      <!--        </v-btn>-->
      <!--      </v-card-title>-->

      <v-card-text class="px-5 py-7" style="border-radius: 8px">
        <v-btn icon small @click="connectForm = false" style="position: absolute; top: 10px; right: 10px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-layout column style="height: 95px" align-center justify-center>
          <h1 class="mb-4">Connect to Monitoring</h1>
          <span>Note: Use a monitoring account to connect to the application</span>
          <span> <a href="https://survey.eofactory.ai" style="color: var(--v-secondary-base)">https://survey.eofactory.ai</a></span>
        </v-layout>
        <v-form @submit="login" onSubmit="return false" class="d-flex flex-column flex-none" v-model="valid"
                ref="formLogin">
          <div style="flex: none;">
            <span style="font-size: 16px; font-weight: bold;">Email</span>
            <div style="width: 100%;" class="pt-1">
              <v-text-field
                  outlined
                  dense
                  class="input-login"
                  v-model="email"
                  label="Email"
                  placeholder="Email"
                  style="color: black !important;"
                  :rules="[rules.requiredUserName]"
              >
              </v-text-field>
            </div>
            <span style="font-size: 16px; font-weight: bold;;">Password</span>
            <div style="width: 100%;" class="pt-1">
              <v-text-field
                  outlined
                  dense
                  class="input-login"
                  :type="isShowPassword ? 'text' : 'password'"
                  v-model="password"
                  label="Password"
                  placeholder="Password"
                  :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="isShowPassword = !isShowPassword"
                  :rules="[rules.requiredPassword]"
              >
              </v-text-field>
            </div>
            <div style="width: 100%; text-align: center;" class="pt-2">
              <v-btn
                  :loading="loading"
                  color="info"
                  width="100%"
                  height="50"
                  type="submit"
                  style="border-radius: 10.5px; font-weight: bold">
                <v-icon class="mr-1">mdi-link-variant</v-icon>
                Connect
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {checkToken, linkMonitoring} from "@/backend"

export default {
  name: "LinkToMonitoring",
  data: () => ({
    connectForm: false,
    loading: false,
    valid: false,
    rules: {
      requiredUserName: value => (!!value && !!value.trim()) || 'Email is required',
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
    },
    email: undefined,
    password: undefined,
    isShowPassword: false
  }),
  methods: {
    openDialog() {
      this.connectForm = true
      this.email = undefined
      this.password = undefined
      this.isShowPassword = undefined
      if (this.$refs.formLogin) this.$refs.formLogin.resetValidation()
    },
    async login() {
      this.$refs.formLogin.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await linkMonitoring({email: this.email, password: this.password})
        // await linkSurvey(res.data)
        const result = await checkToken()
        this.$store.commit('auth/SET_USER', result.data)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.connectForm = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
