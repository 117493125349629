<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.mapboxgl-ctrl-logo{
  display: none !important;
}
.map-info {
  color: #893FF2 !important;
  border: 1px #893FF2 solid !important;
}
.map-info .v-icon {
  color: #893FF2 !important;
}
.mapboxgl-ctrl-scale {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px !important;
  border: none !important;
  color: #434448 !important;
}
</style>
