<template>
  <v-menu :close-on-content-click="true" :nudge-width="240" offset-y>
    <!-- Avatar -->
    <template v-slot:activator="{ attrs, on }">
      <Avatar
          :image="currentUser && currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"
          :name="currentUser && currentUser.email || 'Guest'"
          class="touchable-highlight"
          :class="customClasses"
          v-bind="attrs"
          v-on="on"
      />
    </template>

    <!-- Info -->
    <v-card style="min-width: 12.5em">
      <template v-if="currentUser">
        <v-card-text style="text-align: center;">
          <!--          <v-btn @click="goToLink('/app/profile')" title="Profile" icon small style="position: absolute; top: 43px; right: 97px; z-index: 5"><v-icon size="21">mdi-account-edit-outline</v-icon></v-btn>-->
          <Avatar
              title="Profile" @click="goToLink('/app/profile')"
              :image="currentUser && currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"
              :name="currentUser.email"
              style="border: 2px solid var(--v-primary-base)"
          />
          <div class="pt-2">
            {{ currentUser.email }}
          </div>
          <v-hover v-slot="{ hover }">
            <span class="mt-2" @click="goToLink('/app/profile')" style="width: 60px">
              <a
                  :style="{'font-weight': hover ? 'bold' : 'normal'}"
                  style="color: var(--v-primary-base); text-decoration: underline;">
                Profile
              </a>
            </span>
          </v-hover>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <LogoutButton text small/>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text style="text-align: center">
          <Avatar :image="require('@/assets/images/default-avatar.jpg')" name="Guest"/>
          <div class="my-4">
            Guest
          </div>
          <LoginButton small color="primary"/>
        </v-card-text>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import config from '@/config.json'
import LoginButton from "./LoginButton.vue";
import LogoutButton from "./LogoutButton.vue";
import Avatar from "./Avatar.vue";
import {mapState} from '@/store/ults'
import {toggleDark} from "@/plugins/vuetify";

export default {
  components: {
    LoginButton,
    LogoutButton,
    Avatar
  },

  props: {
    customClasses: {
      type: String,
      default: undefined
    }
  },

  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    transformUrl (url) {
      return config.host + url
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link})
    }
  }
};
</script>

<style scoped>
.touchable-highlight {
  cursor: pointer;
  transition: filter 0.3s, color 0.3s;
}

.touchable-highlight:hover,
.touchable-highlight:focus {
  filter: brightness(1.2);
}

.touchable-highlight:active {
  filter: brightness(0.8);
}

.touchable-highlight--highlight {
  color: var(--primary);
  font-weight: bold;
}
</style>
