import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/MainLayout.vue'
import NotFound from '../views/NotFound'
import {checkAuth} from './auth'
import LandingPage from "@/views/landingPage/LandingPage";
import SignIn from "@/views/signIn/SignIn";
import ForgotPassword from "@/views/forgotPassword/ForgotPassword";
import Register from '@/views/register/Register'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LandingPage,
  },
  {
    path: '/signIn',
    component: SignIn,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
  },
  {
    beforeEnter: checkAuth,
    path: '/app',
    meta: {
      roles: ['admin', 'dashboard']
    },
    component: MainLayout,
    children: [
      {
        path: '',
        meta: {
          roles: ['admin', 'dashboard']
        },
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      }, {
        path: 'userManagement',
        meta: {
          roles: ['admin']
        },
        name: 'User Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/User.vue')
      }, {
        path: 'farm_management',
        meta: {
          roles: ['admin']
        },
        name: 'Farm Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/Farm.vue')
      }, {
        path: 'profile',
        meta: {
          roles: '*'
        },
        name: 'Profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/MyUser.vue')
      }, {
        path: 'survey_management',
        meta: {
          roles: ['admin']
        },
        name: 'Survey Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/Survey.vue')
      }, {
        path: 'example_survey_management',
        meta: {
          roles: ['sysadmin']
        },
        name: 'Example Survey Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/Survey.vue')
      }, {
        path: 'option_management',
        meta: {
          roles: ['admin']
        },
        name: 'Option Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/Option.vue')
      }, {
        path: 'system_control',
        meta: {
          roles: ['sysadmin']
        },
        name: 'System Control',
        component: () => import(/* webpackChunkName: "about" */ '../views/System.vue')
      }, {
        path: 'payment',
        meta: {
          roles: ['admin']
        },
        name: 'payment',
        component: () => import(/* webpackChunkName: "about" */ '../views/Payment.vue')
      },
      {
        path: '401',
        meta: {
          roles: '*'
        },
        name: '401',
        component: () => import(/* webpackChunkName: "about" */ '../views/401.vue')
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'Not Found'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
