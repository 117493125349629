<template>
  <v-app id="inspire" class="main-layout" style="height: 100vh">
    <Message/>
    <v-navigation-drawer
        permanent
        right
        v-model="drawer"
        app
        class="pt-3"
        color="bar"
        mini-variant
    >
      <v-layout class="fill-height" column align-center>
        <div style="border-bottom: 1px solid #fafafa">
          <img @click="goToDashboard" alt="logo" title="EOF Survey" :src="require('@/assets/logo-farm-v1-60x60.png')"
               width="50" height="50" style="cursor: pointer"/>
        </div>
        <div class="pt-1">
          <AvatarPopOver></AvatarPopOver>
        </div>
        <v-list
            v-if="currentUser.is_admin"
            nav
            dense
        >
          <v-list-item-group
              v-model="group"
              mandatory
              active-class="text--primary"
          >
            <v-tooltip left color="#000000" v-for="(item, index) in menu" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item :class="item.id" :value="item" v-bind="attrs" v-on="on" @click="goToLink(item.path)">
                  <v-list-item-icon :class="'list-' + item.id">
                    <v-icon :color="item === group ? 'secondary' : 'white'">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </v-list-item-group>
        </v-list>
        <v-list
            v-if="currentUser.is_sysadmin"
            nav
            dense
        >
          <v-list-item-group
              v-model="group"
              mandatory
              active-class="text--primary"
          >
            <v-tooltip left color="#000000" v-for="(item, index) in sysMenu" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item :class="item.id" :value="item" v-bind="attrs" v-on="on" @click="goToLink(item.path)">
                  <v-list-item-icon :class="'list-' + item.id">
                    <v-icon :color="item === group ? 'secondary' : 'white'">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </v-list-item-group>
        </v-list>
        <v-spacer/>
        <v-tooltip left color="#000000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="accent" height="50" width="100%" min-width="0"
                   class="mb-1 elevation-0"
                   @click="() => !currentUser.monitor_linked ? $refs.linkForm.openDialog() : ''">
              <v-icon :color="currentUser.monitor_linked ? 'warning' : 'white'">mdi-link-variant</v-icon>
            </v-btn>
          </template>
          <span v-if="currentUser.monitor_linked">Connected to Monitoring</span>
          <span v-else>Connect to https://monitoring.eofactory.ai</span>
        </v-tooltip>
        <v-menu left offset-x v-model="helpMenu">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-if="currentUser.is_admin"
                @click="helpMenu = true"
                style="height: 50px;"
                class="elevation-0 btm-menu"
                v-bind="attrs" v-on="on">
              <v-layout class="fill-height" align-center justify-center column>
                <v-icon color="white" size="30">mdi-help-circle-outline</v-icon>
              </v-layout>
            </div>
          </template>
          <v-list dense>
            <v-list-item @click="openDocument">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Document</v-list-item-title>
            </v-list-item>
            <v-list-item @click="startIntro">
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="startIntro">Introduce tour</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
            class="elevation-0 btm-menu"
            v-if="currentUser.is_admin"
            style="height: 70px; border-top: 1px seashell solid">
          <v-layout class="fill-height" align-center justify-center column>
            <span>Token:</span>
            <span>{{ currentUser.token }}</span>
            <v-icon color="white" size="20">mdi-alpha-t-circle-outline</v-icon>
          </v-layout>
        </div>
      </v-layout>
    </v-navigation-drawer>

    <v-main class="d-flex" style="height: calc(100% - 80px)">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-dialog
        width="600"
        persistent
        v-model="errorDialog">
      <v-card>
        <v-card-text>
          <v-layout style="height: 200px" align-center justify-center column>
            <v-btn icon small style="position: absolute; top: 10px; right: 10px" @click="logout">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-icon size="90" color="red">mdi-close-circle</v-icon>
            <span
                style=" line-height: 50px; font-weight: bold; font-size: 20px; color: black">Account Unconfirmed</span>
            <span style="color: #777c85">Please click on link that has just been sent to your email account to verify your email.</span>
            <v-layout justify-start style="width: 100%" class="pl-2">
              <a style="color: #0379cd; text-decoration: underline" @click="resendEmail">Resend</a>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verifyDialog" persistent width="500">
      <v-card>
        <v-card-title class="popup-header">
          Change Password
        </v-card-title>
        <v-card-text class="pa-5">
          <span style="font-size: 14px; font-weight: bold;">Password</span>
          <div style="width: 100%;" class="pt-1">
            <v-text-field
                class="input-login"
                dense
                outlined
                background-color="white"
                v-model="password"
                label="Password"
                :rules="[rules.requiredPassword, rules.sameOldPassword]"
                :type="isShowPassword ? 'text' : 'password'"
                :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="isShowPassword = !isShowPassword">
            </v-text-field>
          </div>
          <span style="font-size: 14px; font-weight: bold;">Confirm Password</span>
          <div style="width: 100%;" class="pt-1">
            <v-text-field
                class="input-login"
                dense
                outlined
                background-color="white"
                v-model="confirmPassword"
                label="Confirm Password"
                :rules="[rules.requiredConfirmPassword]"
                :type="isShowConfirmPassword ? 'text' : 'password'"
                :disabled="!this.password"
                :append-icon="isShowConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="isShowConfirmPassword = !isShowConfirmPassword">
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="loading" width="120" color="primary" @click="verify" dark>
            <v-icon class="mr-1">mdi-check</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LinkToMonitoring ref="linkForm"/>
  </v-app>
</template>

<script>
import {mapState} from "@/store/ults"
import AvatarPopOver from "@/components/AvatarPopOver"
import Language from "@/components/Language"
import {toggleDark} from "@/plugins/vuetify"
import Message from "@/components/Message"
import config from '@/config.json'
import {authLogout, changePassword, getAllDashboardSurvey, getState, resendEmail} from "@/backend"
import LinkToMonitoring from "@/components/home/link/LinkToMonitoring";

export default {
  components: {
    LinkToMonitoring,
    Message,
    AvatarPopOver,
    Language,
  },
  data() {
    return {
      helpMenu: false,
      next: true,
      group: null,
      errorDialog: false,
      loading: false,
      rules: {
        sameOldPassword: value => (!!value && !!value.trim() && value !== '12345678') || 'The new password is the same as the old password',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid'
      },
      isShowPassword: false,
      isShowConfirmPassword: false,
      confirmPassword: undefined,
      password: undefined,
      verifyDialog: false,
      dialog: false,
      drawer: null,
      miniSize: true,
      keyWord: undefined
    }
  },
  computed: {
    ...mapState("main", ["menu", "sysMenu", "isHelp"]),
    ...mapState("auth", ["currentUser"]),
  },
  created() {
    localStorage.removeItem('intro')
    this.isHelp = false
  },
  mounted() {
    if (!this.currentUser) return (window.location.href = `${location.origin}/signIn`)
    if (this.currentUser.is_admin) {
      if (!this.currentUser.verified) this.errorDialog = true
      else this.getFirstData()
    } else if (this.currentUser.is_dashboard) {
      if (this.currentUser.verified) this.getFirstData()
      else this.verifyDialog = true
    }
    this.group = this.menu.find(val => val.path === this.$route.path)
  },
  methods: {
    startIntro() {
      this.isHelp = true
      localStorage.setItem('intro', true)
      this.goToLink('/app')
    },
    openDocument() {
      window.open(config.documentUrl, '_blank')
    },
    async resendEmail() {
      await resendEmail()
      await this.logout()
    },
    async logout() {
      await authLogout()
    },
    goToDashboard() {
      location.href = '/app'
    },
    async verify() {
      try {
        this.loading = false
        await changePassword({
          data: {
            "password": this.password,
            "password_confirmation": this.confirmPassword
          }
        })
        location.reload()
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    getFirstData() {
      this.getStates()
      this.getSurvey()
    },
    async getSurvey() {
      try {
        const res = await getAllDashboardSurvey({per_page: 'all'})
        this.$store.commit('dashboard/SET_SURVEYS', res.data)
      } catch (e) {
      }
    },
    async getStates() {
      try {
        const res = await getState()
        this.$store.commit('dashboard/SET_STATES', res.data)
      } catch (e) {
      }
    },
    // async getTokenWeather() {
    //   try {
    //     const res = await axios.get(`${config.weatherHost}/Auth/Token?username=admin&password=1`)
    //     if (localStorage.getItem('weather_token')) localStorage.removeItem('weather_token')
    //     localStorage.setItem('weather_token', res.data.token)
    //   } catch (e) {
    //   }
    // },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link})
    },
    toggleDark,
  },
};
</script>
<style scoped>
.btm-menu {
  cursor: pointer;
  width: 100%;
  background-color: rgba(0, 136, 3, 0);
  font-size: 11px;
  font-weight: bold;
  color: #fafafa
}

/deep/
.input-login .v-input__slot {
  color: black !important;
}

/deep/
.input-login v-input {
  color: black !important;
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.main-header .v-toolbar__content {
}

.search-input .v-input__slot {
  border-color: red !important;
  min-height: 0 !important;
}

.v-select__selection {
  font-size: 12px !important;
}

.v-main__wrap {
  display: flex;
}
</style>
<style>
html,
body,
.inspire {
  overflow-y: hidden;
  font-family: "Roboto", sans-serif;
}

.logo {
  cursor: pointer;
  font-size: 34px;
  font-weight: 800;
  color: rgb(255, 221, 124);
  line-height: 36px;
}

#bound-menu {
  background-color: transparent;
  z-index: 6;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 0px
}

#menu {
  z-index: 6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--v-backgroundForm-base);
}
</style>
