import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth"
import main from "@/store/modules/main"
import message from "@/store/modules/message"
import aoi from "@/store/modules/aoi"
import point from "@/store/modules/point"
import dataset from "@/store/modules/dataset"
import dashboard from "@/store/modules/dashboard.js"
import record from "@/store/modules/record"
import {s} from './ults'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    s,
  },
  actions: {
    s
  },
  modules: {
    namespaced: true,
    auth,
    main,
    dataset,
    message,
    aoi,
    point,
    dashboard,
    record
  }
})
