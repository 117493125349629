<template>
  <v-app>
    <Message/>
    <div style="height: 100vh">
      <div class="d-flex flex-column" style="height: 100%;">
        <div
            class="d-flex flex pa-3"
            style="background-color: #ffffff"
        >
          <v-layout
              align-center
              class="fill-height"
              :style="{'background-image': `url(${require('@/assets/images/green-gb-login.jpg')}`}"
              style="background-size: 100% 100%"
          >
            <v-container style="width: 75vw; max-height: 100%" class="py-2 overflow-y-auto">
              <v-layout class="fill-height elevation-2" column
                        style="border-radius: 10px; background-color: rgba(118,210,117,0.11);">
                <div
                    style="flex: none; width: 100%; height: 60px; padding-left: 50px; padding-right: 50px; border-bottom: 1px black solid">
                  <div class="d-flex fill-height align-center">
                    <div style="font-size: 35px; color: #43a047; text-transform: uppercase; transform: scale(1, 0.9)">
                      <span style="font-weight: 700; font-variant: small-caps;">Register</span>
                    </div>
                  </div>
                </div>
                <v-form @submit="submitRegister" onSubmit="return false" style="width: 100%" v-model="valid"
                        ref="formRegister">
                  <v-layout class="fill-height">
                    <div class="flex fill-height" style="flex: 1;">
                      <div class="d-flex align-center fill-height flex-wrap" style="width: 100%">
                        <div class="d-flex align-start justify-end fill-height" style="width: 100%">
                          <div style="width: 100%; height: 100%;">
                            <div class="d-flex flex-column fill-height">
                              <div class="d-flex flex-column flex pt-4">
                                <div
                                    style="flex: none; width: 100%; height: 40px; padding-left: 50px; padding-right: 50px;">
                                  <div class="d-flex fill-height align-center">
                                    <div style="font-size: 20px; color: #43a047;">
                                      <span style="font-weight: 700; text-decoration-line: underline">User info</span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                    style="flex: none; padding-left: 50px; padding-right: 50px; padding-bottom: 10px;">
                                  <span style="font-size: 18px; font-weight: bold;">Name</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        background-color="white"
                                        dense
                                        flat
                                        solo
                                        v-model="name"
                                        label="Name"
                                        placeholder="Name"
                                        style="color: black !important;"
                                        :rules="[rules.requiredUserName]"
                                    >
                                    </v-text-field>
                                  </div>
                                  <span style="font-size: 18px; font-weight: bold;">Phone Number</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        background-color="white"
                                        dense
                                        flat
                                        solo
                                        type=""
                                        v-model="phone"
                                        label="Phone number"
                                        placeholder="Phone number"
                                        style="color: black !important;"
                                        :rules="phoneRules"
                                    >
                                    </v-text-field>
                                  </div>
                                  <span style="font-size: 18px; font-weight: bold;">Email</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        background-color="white"
                                        dense
                                        flat
                                        solo
                                        v-model="email"
                                        label="Email"
                                        placeholder="Email"
                                        style="color: black !important;"
                                        :rules="emailRules"
                                    >
                                    </v-text-field>
                                  </div>
                                  <span style="font-size: 18px; font-weight: bold;">Password</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        flat
                                        background-color="white"
                                        solo
                                        v-model="password"
                                        label="Password"
                                        :rules="[rules.requiredPassword]"
                                        :type="isShowPassword ? 'text' : 'password'"
                                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="isShowPassword = !isShowPassword">
                                    </v-text-field>
                                  </div>
                                  <span style="font-size: 18px; font-weight: bold;">Confirm Password</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        flat
                                        background-color="white"
                                        solo
                                        v-model="confirmPassword"
                                        label="Confirm Password"
                                        :rules="[rules.requiredConfirmPassword]"
                                        :type="isShowPassword ? 'text' : 'password'"
                                        :disabled="!this.password"
                                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="isShowPassword = !isShowPassword">
                                    </v-text-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex fill-height" style="flex: 1;">
                      <div class="d-flex align-center fill-height flex-wrap" style="width: 100%">
                        <div class="d-flex align-start justify-end fill-height" style="width: 100%">
                          <div style="width: 100%; height: 100%;">
                            <div class="d-flex flex-column fill-height">
                              <div class="d-flex flex-column flex pt-4">
                                <div
                                    style="flex: none; width: 100%; height: 40px; padding-left: 50px; padding-right: 50px;">
                                  <div class="d-flex fill-height align-center">
                                    <div style="font-size: 20px; color: #43a047; ">
                                      <span
                                          style="font-weight: 700; text-decoration-line: underline">Company info</span>
                                    </div>
                                  </div>
                                </div>
                                <div style="flex: none; padding-left: 50px; padding-right: 50px; padding-bottom: 10px;">
                                  <span style="font-size: 18px; font-weight: bold;">Company Name</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-text-field
                                        class="input-login"
                                        height="50"
                                        background-color="white"
                                        dense
                                        flat
                                        solo
                                        v-model="companyName"
                                        label="Company name"
                                        placeholder="Company name"
                                        style="color: black !important;"
                                        :rules="[rules.requiredUserName]"
                                    >
                                    </v-text-field>
                                  </div>
                                  <span style="font-size: 18px; font-weight: bold;">Country</span>
                                  <div style="width: 100%;" class="pt-1">
                                    <v-autocomplete
                                        class="input-login"
                                        height="50"
                                        background-color="white"
                                        dense
                                        flat
                                        solo
                                        :items="countries"
                                        item-text="name"
                                        item-value="id"
                                        v-model="country"
                                        label="Country"
                                        placeholder="Country"
                                        style="color: black !important;"
                                        :rules="[rules.requiredCountry]"
                                    >
                                    </v-autocomplete>
                                  </div>
                                  <div class="g-recaptcha" id="captcha"></div>
                                  <div style="width: 100%; height: 215px" class="pt-1">
                                    <v-layout class="fill-height align-end">
                                      <v-btn :disabled="loading" @click="goToLink('/signIn')" color="accent" width="120"
                                             class="mr-2">
                                        <v-icon class="mr-1">mdi-home</v-icon>
                                        Sign In
                                      </v-btn>
                                      <v-spacer/>
                                      <v-btn :disabled="loading" width="120" class="mr-2" color="cancel"
                                             @click="resetData">
                                        <v-icon class="mr-1">mdi-close</v-icon>
                                        Cancel
                                      </v-btn>
                                      <v-btn :loading="loading" color="primary" width="120" type="submit">
                                        <v-icon class="mr-1">mdi-check</v-icon>
                                        Submit
                                      </v-btn>
                                    </v-layout>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-layout>
                </v-form>
              </v-layout>
              <v-dialog
                  width="600"
                  persistent
                  v-model="successDialog">
                <v-card>
                  <v-card-text>
                    <v-layout style="height: 300px" align-center justify-center column>
                      <v-btn icon small style="position: absolute; top: 10px; right: 10px" @click="backToLogin"><v-icon>mdi-close</v-icon></v-btn>
                      <v-icon size="90" color="primary">mdi-check-circle</v-icon>
                      <span style=" line-height: 50px; font-weight: bold; font-size: 20px; color: black">
                        A verification link has been sent to your email account
                      </span>
                      <span style="color: #777c85">Please click on link that has just been sent to your email account to verify your email.</span>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-container>
          </v-layout>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  register,
  getCountry
} from "@/backend";
import {mapState} from "@/store/ults"
import config from '@/config.json'
import Message from "@/components/Message"

export default {
  components: {Message},
  data() {
    return {
      successDialog: false,
      loading: false,
      valid: false,
      popupForgot: false,
      rules: {
        requiredCountry: value => !!value || 'Country is required',
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /[0-9]{8,}$/.test(v) || 'Phone number must be more than 7 characters and include only numbers'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      companyName: undefined,
      country: undefined,
      name: undefined,
      phone: undefined,
      confirmPassword: undefined,
      email: undefined,
      password: undefined,
      isShowPassword: false,
      countries: []
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created() {
    this.getCountry()
  },
  mounted() {
    this.initReCaptcha()
  },
  methods: {
    backToLogin () {
      this.successDialog = false
      location.href = `${location.origin}/`
    },
    initReCaptcha () {
      setTimeout(function () {
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render ==='undefined') {
          this.initReCaptcha()
        } else {
          grecaptcha.render('captcha', {
            sitekey: config.SITE_KEY
          })
        }
      }.bind(this), 100)
    },
    async submitRegister() {
      this.$refs.formRegister.validate()
      if (!grecaptcha.getResponse()) return (alert('Function not verified'))
      if (!this.valid ) return
      try {
        this.loading = true
        await register({
          "email": this.email,
          "phone": this.phone,
          "name": this.name,
          "password": this.password,
          "password_confirmation": this.confirmPassword,
          "company":
              {"name": this.companyName, "country_id": this.country}

        })
        this.successDialog = true
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    resetData() {
      this.companyName = undefined
      this.country = undefined
      this.name = undefined
      this.phone = undefined
      this.confirmPassword = undefined
      this.email = undefined
      this.password = undefined
      this.$refs.formRegister.resetValidation()
    },
    async getCountry() {
      try {
        this.loading = true
        const res = await getCountry()
        this.countries = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link});
    }
  }
};
</script>
<style scoped>
/deep/
.input-login .v-input__slot {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  color: black !important;
}

/deep/
.input-login v-input {
  color: black !important;
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}

.header {
  height: 800px;
}

.content {
  height: 100vh
}

.join-button {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.left-info {
  display: flex;
  min-width: 0;
  min-height: 0;
}

@media only screen and (max-width: 925px) {
  .left-info {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.flex {
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}
</style>
<style>
html,
body,
.app {
}
</style>
