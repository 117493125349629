<template>
  <v-btn v-bind="$attrs" @click="login">
    <v-icon class="mr-1" dark>mdi-login-variant</v-icon>
    {{ $t('Login') }}
  </v-btn>
</template>

<script>
import config from '@/config.json'

export default {
  methods: {
    login () {
      window.location.href = `${config.home_page}/signIn`
    }
  }
};
</script>
