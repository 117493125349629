import {getAllSurvey} from "@/backend";

export default {
  namespaced: true,
  state: {
    states: [],
    surveys: []
  },
  getters: {
    states(state) {
      return state.states
    },
    surveys(state) {
      return state.surveys
    }
  },
  mutations: {
    SET_STATES (state, newValue) {
      state.states = newValue
    },
    SET_SURVEYS (state, newValue) {
      state.surveys = newValue
    }
  },
}
