export default {
  namespaced: true,
  state: {
    currentPoint: undefined
  },
  getters: {
      currentAOI (state) {
        return state.currentPoint
      }
  },
  mutations: {
    SET_POINT (state, newValue) {
      state.currentPoint = newValue
    },
  }
}
