
export default {
  namespaced: true,
  state: {
    isHelp: false,
    menu: [{
      id: 'dashboard',
      icon: 'mdi-view-dashboard-outline',
      name: 'DashBoard',
      path: '/app',
      description: 'You are at Dashboard Screen.',
    }, {
      id: 'user',
      icon: 'mdi-account-circle-outline',
      name: 'User management',
      path: '/app/userManagement',
      description: '',
    },{
      id: 'survey',
      icon: 'mdi-file-document-outline',
      name: 'Survey management',
      path: '/app/survey_management',
      description: '',
    }, {
      id: 'option',
      icon: 'mdi-form-select',
      name: 'Option management',
      path: '/app/option_management',
      description: '',
    }, {
      id: 'payment',
      icon: 'mdi-currency-usd',
      name: 'Payment',
      path: '/app/payment',
      description: '',
    }],
    sysMenu: [{
      id: 'company',
      icon: 'mdi-view-dashboard-outline',
      name: 'Company',
      path: '/app/system_control'
    }, {
      id: 'user',
      icon: 'mdi-file-document-outline',
      name: 'Survey management',
      path: '/app/example_survey_management'
    }]
  },

  mutations: {
  }
}
