import Store from '@/store'
import config from '@/config.json'
import api from '@/api'

export async function checkAuth(to, from, next) {
  try {
    if (to.query.token) {
      const token = to.query.token
      api.setToken(JSON.parse(window.atob(token)).access_token)
      next(to.path)
    }
    if (!api.getToken()) {
      throw "token"
    }
    let result = await api.get(`${config.backend}/auth/me`)
    result.data.role = result.data.is_admin ? 'admin' : result.data.is_sysadmin ? 'sysadmin' : result.data.is_dashboard ? 'dashboard' : 'mobile'
    Store.commit('auth/SET_USER', result.data)
    if (to.meta.roles === '*' || to.meta.roles.some(val => val === result.data.role)) next()
    else if (result.data.is_sysadmin) location.href = `${location.origin}/app/system_control`
    else location.href = `${location.origin}/app/401`
  } catch (e) {
    // if (e === "token" || (e.response && e.response.status === 500)) {
      console.log(e)
    window.location.href = `${location.origin}/signIn`
    // }
  } finally {
    next()
  }
}
