<template>
  <v-app>
    <Message/>
    <div style="height: 100vh">
      <div class="d-flex flex-column" style="height: 100%;">
        <div
            class="d-flex flex"
            style="background-color: #ffffff"
        >
          <v-layout
              align-center
              class="fill-height"
              :style="{'background-image': `url(${require('@/assets/images/green1.jpg')}`}"
              style="background-size: 100% 100%"
          >
            <div class="d-flex align-center flex-wrap pt-8 pb-2 fill-height"
                 style="width: 100%; background-color: rgba(123,159,106,0.25); padding: 0 100px">
              <div style="flex: 6; height: 100%">
                <v-layout align-center justify-center class="fill-height">
                  <div style="width: 100%; height: 100%">
                    <v-layout column align-center justify-center class="fill-height">
                      <div style="font-size: 52px; font-weight: bold; color: #047917">
                        WELCOME TO EOF SURVEY
                      </div>
                      <div style="font-size: 28px; color: #416441">
                        Save Environment! Save our planet Earth!
                      </div>
                      <div class="pt-3 flex-none pb-2" style="width: 100%">
                        <v-layout justify-center align-center>
                          <div class="d-flex justify-center"
                               style="width: 160px; text-transform: uppercase; font-weight: 400; color: #fce018; font-size: 28px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">
                            monitor
                          </div>
                          <div class="d-flex justify-center"
                               style="width: 160px; text-transform: uppercase; font-weight: 400; color: #fce018; font-size: 28px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">
                            detect
                          </div>
                          <div class="d-flex justify-center"
                               style="width: 160px; text-transform: uppercase; font-weight: 400; color: #fce018; font-size: 28px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">
                            identify
                          </div>
                        </v-layout>
                      </div>
                      <v-layout
                          style="width: 100%; height: 310px; max-height: 350px; position: relative; padding-left: 40px"
                          align-center
                                justify-center>
                        <img alt="welcome" :src="require('@/assets/images/Mask-Group.png')" width="auto" height="305px"
                             style="object-fit: cover; border-radius: 12px">
                        <v-layout column class="fill-height pt-12 pl-4">
                          <div style="width: 120px; height: 105px" class="pl-2">
                            <img alt="welcome" :src="require('@/assets/images/qrcode_100x100.png')" width="auto" height="100px"
                                 style="object-fit: cover;">
                          </div>
                          <span class="ml-2" style="color: #fafafa; font-size: 14px">Scan QR Code on the screen
                          <p class="my-1">
                             or use URL:
                            <a href="https://play.google.com/store/apps/details?id=com.skymapglobal.eofsurvey">
                               https://play.google.com/store/apps/details?id=com.skymapglobal.eofsurvey
                            </a>
                          </p>
                          to download EOF Survey mobile app.</span>
                        </v-layout>
                      </v-layout>
<!--                      <v-spacer/>-->
<!--                      <div style="color: #fafafa">Presented by SkymapGlobal.com | powered by EOFactory.ai</div>-->
                    </v-layout>
                  </div>
                </v-layout>
              </div>
              <div style="flex: 2; height: 550px;">
                <div class="d-flex align-start justify-end fill-height"
                     style=" background-color: rgba(121,171,121,0.33); border-radius: 8px">
                  <div style="width: 100%; height: 100%; border-radius: 10px" class="elevation-2">
                    <div class="d-flex flex-column fill-height pt-9">
                      <div style="flex: none; width: 100%; height: 60px; padding-left: 20px; padding-right: 20px">
                        <div class="d-flex fill-height align-center">
                          <div class="pl-1 logo">
                            <v-layout class="fill-height" align-center>
                              <img alt="logo" :src="require('@/assets/logo-farm-v1.png')" width="55" height="55"
                                   class="mr-2"/>
                              <span style="color: #FF921E">
                                EOF
                                <span style="color: #047917"> SURVEY</span>
                              </span>
                            </v-layout>
                          </div>
                          <v-spacer/>
                        </div>
                      </div>
                      <div class="d-flex flex-column flex pt-4 justify-center">
                        <v-form @submit="login" onSubmit="return false" class="d-flex flex-column flex-none"
                                v-model="valid" ref="formLogin">
                          <div style="flex: none; padding-left: 30px; padding-right: 30px; padding-bottom: 50px;">
                            <span style="font-size: 20px; font-weight: bold;">Email</span>
                            <div style="width: 100%;" class="pt-3">
                              <v-text-field
                                  class="input-login"
                                  height="50"
                                  background-color="white"
                                  dense
                                  flat
                                  solo
                                  v-model="email"
                                  label="Email"
                                  placeholder="Email"
                                  style="color: black !important;"
                                  :rules="[rules.requiredUserName]"
                              >
                              </v-text-field>
                            </div>
                            <span style="font-size: 20px; font-weight: bold;;">Password</span>
                            <div style="width: 100%;" class="pt-3">
                              <v-text-field
                                  class="input-login"
                                  height="50"
                                  flat
                                  background-color="white"
                                  solo
                                  :type="isShowPassword ? 'text' : 'password'"
                                  v-model="password"
                                  label="Password"
                                  placeholder="Password"
                                  :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="isShowPassword = !isShowPassword"
                                  :rules="[rules.requiredPassword]"
                              >
                              </v-text-field>
                            </div>
                            <div style="width: 100%; height: 20px;">
                              <div class="fill-height d-flex justify-end">
                                <ForgotPassword/>
                              </div>
                            </div>
                            <div style="width: 100%; text-align: center;" class="pt-5">
                              <v-btn :loading="loading" color="primary" width="100%" height="50" type="submit"
                                     style="border-radius: 10.5px; text-transform: capitalize; font-weight: bold">Log in
                              </v-btn>
                            </div>
                            <div style="width: 100%; text-align: center; font-size: 18px; color: #fafafa"
                                 class="pt-5 mt-4">
                              New User? <a @click="goToLink('/register')" style="font-weight: bold; color: #76d275">Register </a>here.
                            </div>
                          </div>
                        </v-form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
        </div>
      </div>
      <v-dialog
          width="500"
          v-model="verifyDialog">
        <v-card>
          <v-card-text>
            <v-layout style="height: 200px" align-center justify-center column>
              <v-btn icon small style="position: absolute; top: 10px; right: 10px" @click="verifyDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-icon size="90" color="primary">mdi-check-circle</v-icon>
              <span style=" line-height: 50px; font-weight: bold; font-size: 20px; color: black">Verified!</span>
              <span style="color: #777c85">You have successfully verified account.</span>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import {
  authLogin,
  checkToken
} from "@/backend";
import {mapState} from "@/store/ults";
import api from "@/api";
import ForgotPassword from "@/views/forgotPassword/ForgotDialog";
import Message from "@/components/Message";

export default {
  components: {
    Message,
    ForgotPassword
  },
  data: () => ({
    verifyDialog: false,
    loading: false,
    valid: false,
    popupForgot: false,
    rules: {
      requiredUserName: value => (!!value && !!value.trim()) || 'Email is required',
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
    },
    email: undefined,
    password: undefined,
    isShowPassword: false
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created() {
    const token = api.getToken()
    if (token) this.checkTokenExist()
  },
  mounted() {
    if (this.$route.query.verified) this.verifyDialog = true
  },
  methods: {
    async login() {
      this.$refs.formLogin.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await authLogin({email: this.email, password: this.password})
        location.href = `${location.origin}/app`
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async checkTokenExist() {
      try {
        const res = await checkToken()
        location.href = `${location.origin}/app`
      } catch (e) {
        api.deleteToken()
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link});
    }
  }
};
</script>
<style scoped>
/deep/
.input-login .v-input__slot {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  color: black !important;
}

/deep/
.input-login v-input {
  color: black !important;
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}

.header {
  height: 800px;
}

.content {
  height: 100vh
}

.join-button {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.left-info {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.logo {
  cursor: pointer;
  font-size: 40px;
  font-weight: 800;
  color: rgb(255, 221, 124);
  line-height: 36px;
}

@media only screen and (max-width: 925px) {
  .left-info {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.flex {
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}
</style>
<style>
html,
body,
.app {
}
</style>
